import React, { Component, Fragment } from 'react';
import authService from './api-authorization/AuthorizeService'

export class Overview extends Component {
    static displayName = Overview.name;

    constructor(props) {
        super(props);
        this.state = { buildings: [], loading: true };
        this.deleteUnit = this.deleteUnit.bind(this);
        this.updateMarketValue = this.updateMarketValue.bind(this);
        this.renderBuildingList = this.renderBuildingList.bind(this);
    }

    componentDidMount() {
        this.populateBuildingData();
    }

    renderBuildingList(buildings) {
        const vacancies = buildings.reduce(function (unitCount, building) {
            return unitCount + building.units.filter(u => u.vacant).length;
        }, 0);
        const now = (new Date(Date.now())).getTime();
        const sixtyDays = 60 * 1000 * 60 * 60 * 24 + now;
        const available = buildings.reduce(function (unitCount, building) {
            return unitCount + building.units.filter(u => u.vacant && sixtyDays >= (new Date(u.availableDate)).getTime()
                    ).length;
        }, 0);
        const renos = buildings.reduce(function (unitCount, building) {
            return unitCount + building.units.filter(m => m.maintStatus && m.maintStatus.includes(0)).length;
        }, 0);

        return (
            <div style={{ width: "max-content", minWidth: 1200 }}>
                <h1>Overview</h1>
                <div>
                    <div>
                        <a href="/unit" className={"link"}>Add a Vacancy</a>
                    </div>
                    <div>
                        <a href="/building" className={"link"}>Add a Building</a>
                    </div>
                </div>
                <br />
                <table>
                    <thead>
                        <tr style={{ border: "none" }}>
                            <th></th>
                            <th width="10"></th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr style={{ border: "none" }}>
                            <td><h6><b>Total current vacancies:</b></h6></td>
                            <td></td>
                            <td><h6><b>{vacancies}</b></h6></td>
                        </tr>
                        <tr style={{ border: "none" }}>
                            <td><h6> <b>Availability in next 60 days:</b> </h6></td>
                            <td></td>
                            <td> <h6><b>{available}</b></h6></td>
                        </tr>
                        <tr style={{ border: "none" }}>
                            <td><h6><b>Upcoming renovations:</b></h6></td>
                            <td></td>
                            <td><h6><b>{renos}</b></h6></td>
                        </tr>
                    </tbody>
                </table>
                <br />
                <div className={"legend-box"}>
                    <span className={"legend"}><span style={{ background: "yellow" }} className={"status-box"}>H</span> - Hold</span>
                    <span className={"legend"}><span style={{ background: "#c673d5" }} className={"status-box"}>D</span> - Deposit</span>
                    <span className={"legend"}><span style={{ background: "orange" }} className={"status-box"}>T</span> - Transfer</span>

                    <span className={"legend"}><span style={{ background: "red" }} className={"status-box"}>V</span> - Vacant</span>
                    <span className={"legend"}><span style={{ background: "lightgreen" }} className={"status-box"}>L</span> - Leased</span>

                    <span className={"legend"}><span style={{ background: "darkgreen", color: "white" }} className={"status-box"}>O</span> - Open</span>
                    <span className={"legend"}><span style={{ background: "blue", color: "white" }} className={"status-box"}>R</span> - Reno</span>
                </div>
                <br/>
                {buildings.map((b, i) => {
                    var a = b.address;
                    const vacanciesForBuilding = b.units.filter(u => u.vacant).length ?? 0;
                    const availableForBuilding = b.units.filter(u => u.vacant && sixtyDays >= (new Date(u.availableDate)).getTime()
                        ).length ?? 0;
                    const renos = b.units.filter(m => m.maintStatus && m.maintStatus.includes(0)).length ?? 0;

                    return (
                        <div key={i} style={{ width: "fit-content" }}>
                            <div>
                                <div style={{ width: "100%", display: "inline-block" }}>
                                    <h3 style={{ float: "left", marginRight: 5, color: "royalblue", marginBottom: -5 }}>{b.name}</h3>
                                    <a href={"/building/" + b.id} style={{ float: "right" }} className={"link"}>Edit</a>
                                </div>
                                <div>
                                    <div>{a.line1}</div>
                                    {a.line2 ? <div>{a.line2}</div> : <></>}
                                    <div>{a.city ? a.city + ", " : ""} {a.state} {a.postalCode}</div>
                                    <div>{b.phoneNumber}</div>
                                </div>
                            </div>
                            <div style={{ width: "fit-content", marginTop: 10 }}>
                                <table>
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th width="10"></th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr style={{ border: "none" }}>
                                            <td><h6><b>Total current vacancies:</b></h6></td>
                                            <td></td>
                                            <td><h6><b>{vacanciesForBuilding}</b></h6></td>
                                        </tr>
                                        <tr style={{ border: "none" }}>
                                            <td><h6> <b>Availability in next 60 days:</b></h6></td>
                                            <td></td>
                                            <td><h6><b>{availableForBuilding}</b></h6></td>
                                        </tr>
                                        <tr style={{ border: "none" }}>
                                            <td><h6><b>Upcoming renovations:</b></h6></td>
                                            <td></td>
                                            <td><h6><b>{renos}</b></h6></td>
                                        </tr>
                                    </tbody>
                                </table>
                                <br />
                                <table style={{ borderBottom: "1px solid black" }}>
                                    <thead>
                                        <tr>
                                            <th width="100">Unit</th>
                                            <th width="60">Bed</th>
                                            <th width="60">Bath</th>
                                            <th width="80">Price</th>
                                            <th width="120">Availability</th>
                                            <th width="100">MO</th>
                                            <th width="100">MI</th>
                                            <th style={{ minWidth: 90 }}>Leasing</th>
                                            <th style={{ minWidth: 80 }}>Maint</th>
                                            <th style={{ minWidth: 200 }}>Notes</th>
                                            <th width="50"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {b.units.map((unit, i2) => {
                                            const availDate = Overview.getDateForDisplay(unit.availableDate);
                                            const moveOutDate = Overview.getDateForDisplay(unit.moveOutDate);
                                            const moveInDate = Overview.getDateForDisplay(unit.moveInDate);
                                            return (
                                                <tr key={i2}>
                                                    <td>{unit.name}
                                                        {unit.unitStatus == 0 ? (<span style={{ background: "yellow" }} className={"status-box"}>H</span>) : <></>}
                                                        {unit.unitStatus == 1 ? (<span style={{ background: "#c673d5" }} className={"status-box"}>D</span>) : <></>}
                                                        {unit.unitStatus == 2 ? (<span style={{ background: "orange" }} className={"status-box"}>T</span>) : <></>}
                                                    </td>
                                                    <td>{unit.bed}</td>
                                                    <td>{unit.bath}</td>
                                                    <td>${unit.price}</td>
                                                    <td>{availDate}</td>
                                                    <td>{moveOutDate}</td>
                                                    <td>{moveInDate}</td>
                                                    <td>
                                                        {unit.vacant ?
                                                            (<span style={{ background: "red" }} className={"status-box"}>V</span>) :
                                                            (<span style={{ background: "lightgreen" }} className={"status-box"}>L</span>)}
                                                    </td>
                                                    <td style={{ textAlign: "left" }}>
                                                        {unit.maintStatus && unit.maintStatus.includes(1) ? <span style={{ background: "darkgreen", color: "white" }} className={"status-box"}>O</span>:<></>}
                                                        {unit.maintStatus && unit.maintStatus.includes(0) ? <span style={{ background: "blue", color: "white" }} className={"status-box"}>R</span> : <></>}
                                                        
                                                    </td>
                                                    <td>{unit.notes}</td>
                                                    <td>
                                                        <a className={"link"} href={"/unit/" + unit.id}>e</a> | <button onClick={(() => this.deleteUnit(unit))} className={"link"}>d</button>
                                                    </td>
                                                </tr>)
                                        })}
                                    </tbody>
                                </table>
                            </div>
                            <br/>
                            <br/>
                        </div>)
                })}
                <hr/>
                <h1>Market Value Matrix</h1>
                <br />
                <div style={{ marginTop: 10, minWidth: 1040 }}>
                    <table className="value-matrix">
                        <thead>
                            <tr>
                                <th>Property</th>
                                <th width="100">Last Updated</th>
                                <th style={{ textAlign: "right" }}>Studio</th>
                                <th style={{ textAlign: "right" }}>1Bd</th>
                                <th style={{ textAlign: "right" }}>2Bd</th>
                            </tr>
                        </thead>
                        <tbody>
                            {buildings.map((b, i3) => {
                                    
                                if (b.marketValue && !b.isRenovation) {
                                    const lastChange = Overview.getDateForDisplay(b.marketValue.lastChanged);
                                    return (
                                        <tr key={i3} style={{ border: "solid" }}>
                                            <td><h6 style={{ marginRight: 10, fontWeight: "bold" }}>{b.name}</h6></td>
                                            <td><h6>{lastChange}</h6></td>
                                            <td ><span style={{marginLeft: 10}}>$</span>
                                                <input type="number" min="0"
                                                    onChange={(e) => {
                                                        this.setState({
                                                            ...this.state, b: {
                                                                ...b,
                                                                marketValue: {
                                                                    ...b.marketValue,
                                                                    studio: Number(e.target.value)
                                                                }
                                                            }
                                                        })
                                                    }}
                                                    defaultValue={b.marketValue.studio}
                                                    onBlur={(() => this.updateMarketValue())}
                                                />
                                            </td>
                                            <td><span style={{ marginLeft: 10 }}>$</span>
                                                <input type="number" min="0"
                                                    onChange={(e) => {
                                                        this.setState({
                                                            ...this.state, b: {
                                                                ...b,
                                                                marketValue: {
                                                                    ...b.marketValue,
                                                                    oneBed: Number(e.target.value)
                                                                }
                                                            }
                                                        })
                                                    }}
                                                    defaultValue={b.marketValue.oneBed}
                                                    onBlur={(() => this.updateMarketValue())}
                                                />
                                            </td>
                                            <td><span style={{ marginLeft: 10 }}>$</span>
                                                <input type="number" min="0"
                                                    onChange={(e) => {
                                                        this.setState({
                                                            ...this.state, b: {
                                                                ...b,
                                                                marketValue: {
                                                                    ...b.marketValue,
                                                                    twoBedOneBath: Number(e.target.value)
                                                                }
                                                            }
                                                        })
                                                    }}
                                                    defaultValue={b.marketValue.twoBedOneBath}
                                                    onBlur={(() => this.updateMarketValue())}
                                                />
                                            </td>
                                        </tr>
                                    )
                                }
                            })}
                        </tbody>
                    </table>
                </div>
            </div>

        );
    }

    render() {
        const buildingContents = this.state.loading
            ? <p><em>Loading...</em></p>
            : this.renderBuildingList(this.state.buildings);

        return (
            <div>
                {buildingContents}
            </div>
        );
    }

    async populateBuildingData() {
        const token = await authService.getAccessToken();
        var response = await fetch('api/building', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const data = await response.json();
        this.setState({ buildings: data, loading: false });
    }

    async deleteUnit(unit) {
        if (window.confirm("Are you sure you want to delete Unit " + unit.name + "?")) {
            if (window.confirm("Are you really sure you want to delete Unit " + unit.name + "?"))
            {
                const token = await authService.getAccessToken();
                var response = await fetch('api/unit/delete/' + Number(unit.id), {
                    method: 'DELETE',
                    headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
                });
                await this.populateBuildingData();
                // TODO - delete item from buildings data
            }
        }
    }

    async updateMarketValue() {
        if (this.state.b) {
            let token = await authService.getAccessToken();
            var response = await fetch('api/marketValue/addOrUpdate', {
                method: 'POST',
                body: JSON.stringify(this.state.b.marketValue),
                headers: !token ? {} : { 'Authorization': `Bearer ${token}`, 'Content-type': 'application/json' }
            });

            if (response.ok) {
                await this.populateBuildingData();
            } else {
                alert(await response.text());
            }
        }
    }

    /**
     * Get datetime object as MM-DD-YY formated date string
     * @param {any} date The datetime object
     * @returns The date string
     */
    static getDateForDisplay(date) {
        if (date) {
            return new Date(date).toLocaleString('en-US', { month: "2-digit", day: "2-digit", year: "2-digit" }).replaceAll("/", "-");
        } else {
            return "";
        }
    }
}
