import React, { Component } from 'react';
import { Container } from 'reactstrap';
import { NavMenu } from './NavMenu';

export class Layout extends Component {
    static displayName = Layout.name;

    render() {
        return (
            <div>
                <NavMenu />
                <Container className={"contents"}>
                    {this.props.children}
                </Container>
                <footer class="footer border-top text-muted">
                    <div class="container">
                        &copy; 2023 - Skyline Real Estate Services LLC
                    </div>
                </footer>
            </div>
        );
    }
}
