import React, { Component } from 'react';
import authService from './api-authorization/AuthorizeService'

export class UnitEdit extends Component {
    static displayName = UnitEdit.name;
    static debounce = false;

    maintOptions = [
        { label: 'Reno', value: 0 },
        { label: 'Open', value: 1 }
    ];

    constructor(props) {
        super(props);
        this.renderForm = this.renderForm.bind(this);
        this.addVacancy = this.addVacancy.bind(this);
        this.maintOptionsChanged = this.maintOptionsChanged.bind(this);
        this.unitId = window.location.href.split('/')[4];
        this.state = {
            buildings: [], loading: true
        };
    }

    componentDidMount() {
        this.populateBuildingData();
    }

    renderForm(buildings) {
        return (<>
            <h1>Unit Edit</h1>
            <table>
                <tbody>
                    <tr style={{ border: "none" }}>
                        <td>Complex</td>
                        <td>
                            <select onChange={(e) => {
                                this.setState({
                                    ...this.state, unit: {
                                        ...this.state.unit,
                                        BuildingId: Number(e.target.value)
                                    }
                                })
                            }} defaultValue={this.state.unit.BuildingId}>
                                {buildings.map((b, i) => (
                                    <option key={i} value={ b.id }>{ b.name }</option>
                                ))}
                            </select>
                        </td>
                    </tr>
                    <tr style={{ border: "none" }}>
                        <td>Unit Number</td>
                        <td><input type="text"
                            onChange={(e) => {
                                this.setState({
                                    ...this.state, unit: {
                                        ...this.state.unit,
                                        Name: e.target.value
                                    }
                                })
                            }} defaultValue={this.state.unit.Name} />
                        </td>
                    </tr>
                    <tr style={{ border: "none" }}>
                        <td>Bedrooms</td>
                        <td><input type="number" defaultValue={this.state.unit.Bed} min="0" onChange={(e) => {
                            this.setState({
                                ...this.state, unit: {
                                    ...this.state.unit,
                                    Bed: Number(e.target.value)
                                }
                            })
                        }} /></td>
                    </tr>
                    <tr style={{ border: "none" }}>
                        <td>Bathrooms</td>
                        <td><input type="number" defaultValue={this.state.unit.Bath} min="0" onChange={(e) => {
                            this.setState({
                                ...this.state, unit: {
                                    ...this.state.unit,
                                    Bath: Number(e.target.value)
                                }
                            })
                        }} /></td>
                    </tr>
                    <tr style={{ border: "none" }}>
                        <td>Price</td>
                        <td><input type="number" defaultValue={this.state.unit.Price} min="0" onChange={(e) => {
                            this.setState({
                                ...this.state, unit: {
                                    ...this.state.unit,
                                    Price: Number(e.target.value)
                                }
                            })
                        }} /></td>
                    </tr>
                    <tr style={{ border: "none" }}>
                        <td>Availability</td>
                        <td><input type="date" defaultValue={this.state.unit.AvailableDate ? new Date(this.state.unit.AvailableDate).toISOString().split('T')[0] : null} onChange={(e) => {
                            this.setState({
                                ...this.state, unit: {
                                    ...this.state.unit,
                                    AvailableDate: e.target.value
                                }
                            })
                        }} /></td>
                    </tr>
                    <tr style={{ border: "none" }}>
                        <td>Move In</td>
                        <td><input type="date" defaultValue={this.state.unit.MoveInDate ? new Date(this.state.unit.MoveInDate).toISOString().split('T')[0] : null} onChange={(e) => {
                            this.setState({
                                ...this.state, unit: {
                                    ...this.state.unit,
                                    MoveInDate: e.target.value
                                }
                            })
                        }} /></td>
                    </tr>
                    <tr style={{ border: "none" }}>
                        <td>Move Out</td>
                        <td><input type="date" defaultValue={this.state.unit.MoveOutDate ? new Date(this.state.unit.MoveOutDate).toISOString().split('T')[0] : null} onChange={(e) => {
                            this.setState({
                                ...this.state, unit: {
                                    ...this.state.unit,
                                    MoveOutDate: e.target.value
                                }
                            })
                        }} /></td>
                    </tr>
                    <tr style={{ border: "none" }}>
                        <td>Vacant</td>
                        <td><input type="checkbox" defaultChecked={this.state.unit.Vacant} style={{ cursor: "pointer" }}
                            onChange={(e) => {
                            this.setState({
                                ...this.state, unit: {
                                    ...this.state.unit,
                                    Vacant: e.target.checked
                                }
                            })
                        }} /></td>
                    </tr>
                    <tr style={{ border: "none" }}>
                        <td>Maint Status</td>
                        <td>
                            <div>
                                <label style={{ cursor: "pointer" }} className={"noselect"}>
                                    <input type="checkbox" style={{ marginRight: 5, cursor: "pointer" }}
                                        defaultChecked={this.state.unit.MaintStatus && this.state.unit.MaintStatus.includes(0)}
                                        onChange={(e) => { this.maintOptionsChanged(e, 0); }}/>
                                    Reno
                                </label>
                            </div>
                            <div>
                                <label style={{ cursor: "pointer" }} className={"noselect"}>
                                    <input type="checkbox" style={{ marginRight: 5, cursor: "pointer" }}
                                        defaultChecked={this.state.unit.MaintStatus && this.state.unit.MaintStatus.includes(1)}
                                        onChange={(e) => { this.maintOptionsChanged(e, 1); }}/>
                                    Open
                                </label>
                            </div>
                        </td>
                    </tr>
                    <tr style={{ border: "none" }}>
                        <td>Unit Status</td>
                        <td>
                            <select defaultValue={this.state.unit.UnitStatus} onChange={(e) => {
                                this.setState({
                                    ...this.state, unit: {
                                        ...this.state.unit,
                                        UnitStatus: e.target.value ? e.target.value : null
                                    }
                                })
                            }}>
                                <option defaultValue></option>
                                <option value="0">Hold</option>
                                <option value="1">Deposit</option>
                                <option value="2">Transfer</option>
                            </select>
                        </td>
                    </tr>
                    <tr style={{ border: "none" }}>
                        <td>Notes</td>
                        <td><input type="text" defaultValue={this.state.unit.Notes} onChange={(e) => {
                            this.setState({
                                ...this.state, unit: {
                                    ...this.state.unit,
                                    Notes: e.target.value
                                }
                            })
                        }} /></td>
                    </tr>
                    <tr style={{ border: "none" }}>
                        <td></td>
                        <td>
                            <button onClick={this.addVacancy}>{this.unitId?"Update":"Add"} Vacancy</button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </>);
    }

    render() {
        const contents = this.state.loading
            ? <p><em>Loading...</em></p>
            : this.renderForm(this.state.buildings);
        
        return contents;
    }

    maintOptionsChanged(e, id) {
        let maintStatus = this.state.unit.MaintStatus;
        if (maintStatus) {
            if (e.target.checked) {
                maintStatus.push(id);
            } else {
                let statIndex = maintStatus.indexOf(id);
                maintStatus.splice(statIndex, 1)
            }
            this.setState({
                ...this.state,
                unit: {
                    ...this.state.unit,
                    MaintStatus: maintStatus
                }
            });
        } else {
            this.setState({
                ...this.state,
                unit: {
                    ...this.state.unit,
                    MaintStatus: [id]
                }
            });
        }
    }

    async populateUnitData() {
        if (this.unitId >= 0) {
            const token = await authService.getAccessToken();
            var response = await fetch('api/unit/' + this.unitId, {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
            });
            let unitResponse = await response.json();
            this.setState({
                ...this.state,
                loading: false,
                unit: {
                    Id: Number(unitResponse.id),
                    BuildingId: Number(unitResponse.buildingId),
                    Name: unitResponse.name,
                    Bed: Number(unitResponse.bed),
                    Bath: Number(unitResponse.bath),
                    Price: Number(unitResponse.price),
                    AvailableDate: unitResponse.availableDate,
                    MoveInDate: unitResponse.moveInDate,
                    MoveOutDate: unitResponse.moveOutDate,
                    Vacant: unitResponse.vacant,
                    MaintStatus: unitResponse.maintStatus ? unitResponse.maintStatus : [],
                    UnitStatus: unitResponse.unitStatus,
                    Notes: unitResponse.notes
                }
            });
        }
        else {
            this.setState({
                ...this.state,
                loading: false
            });
        }
    }

    async populateBuildingData() {
        const token = await authService.getAccessToken();
        var response = await fetch('api/building', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const data = await response.json();
        this.setState({
            ...this.state,
            buildings: data,
            unit: {
                BuildingId: Number(data[0].id),
                Name: "",
                Bed: 0,
                Bath: 0,
                Price: 0,
                AvailableDate: null,
                MoveInDate: null,
                MoveOutDate: null,
                Vacant: false,
                MaintStatus: null,
                UnitStatus: null,
                Notes: null
            }
        }, this.populateUnitData);
    }

    async addVacancy() {
        if (!UnitEdit.debounce) {
            UnitEdit.debounce = true;
            let token = await authService.getAccessToken();
            var response = await fetch('api/unit/addOrUpdate', {
                method: 'POST',
                body: JSON.stringify(this.state.unit),
                headers: !token ? {} : { 'Authorization': `Bearer ${token}`, 'Content-type': 'application/json' }
            });

            if (response.ok) {
                if (this.state.unit.Id) {
                    alert("Unit updated")
                } else {
                    alert("Unit added")
                }
                window.location.href = '/overview';
            } else {
                alert(await response.text())
                UnitEdit.debounce = false;
            }
        }
    }
}
