import React, { Component, Fragment } from 'react';
import authService from './api-authorization/AuthorizeService'

export class BuildingEdit extends Component {
    static displayName = BuildingEdit.name;
    static debounce = false;

    constructor(props) {
        super(props);
        this.renderForm = this.renderForm.bind(this);
        this.addBuilding = this.addBuilding.bind(this);
        this.deleteBuilding = this.deleteBuilding.bind(this);
        this.buildingId = window.location.href.split('/')[4];
        this.state = {
            loading: true
        };
    }

    componentDidMount() {
        this.populateBuildingData();
    }

    renderForm() {
        return (<>
            <h1>Building Edit</h1>
            <table>
                <tbody>
                    <tr style={{ border: "none" }}>
                        <td>Complex Name</td>
                        <td><input type="text"
                            onChange={(e) => {
                                this.setState({
                                    ...this.state, building: {
                                        ...this.state.building,
                                        Name: e.target.value
                                    }
                                })
                            }} defaultValue={this.state.building.Name} />
                        </td>
                    </tr>
                    <tr style={{ border: "none" }}>
                        <td>Phone Number</td>
                        <td><input type="text"
                            onChange={(e) => {
                                this.setState({
                                    ...this.state, building: {
                                        ...this.state.building,
                                        PhoneNumber: e.target.value
                                    }
                                })
                            }} defaultValue={this.state.building.PhoneNumber} />
                        </td>
                    </tr>
                    <tr style={{ border: "none" }}>
                        <td>Acquired Date</td>
                        <td><input type="date"
                            onChange={(e) => {
                                this.setState({
                                    ...this.state, building: {
                                        ...this.state.building,
                                        AcquiredDate: e.target.value
                                    }
                                })
                            }} defaultValue={this.state.building.AcquiredDate ? new Date(this.state.building.AcquiredDate).toISOString().split('T')[0] : null} />
                        </td>
                    </tr>
                    <tr style={{ border: "none" }}>
                        <td>Line 1</td>
                        <td><input type="text"
                            onChange={(e) => {
                                this.setState({
                                    ...this.state, building: {
                                        ...this.state.building,
                                        Address: {
                                            ...this.state.building.Address,
                                            Line1: e.target.value
                                        }
                                    }
                                })
                            }} defaultValue={this.state.building.Address.Line1} />
                        </td>
                    </tr>
                    <tr style={{ border: "none" }}>
                        <td>Line 2</td>
                        <td><input type="text"
                            onChange={(e) => {
                                this.setState({
                                    ...this.state, building: {
                                        ...this.state.building,
                                        Address: {
                                            ...this.state.building.Address,
                                            Line2: e.target.value
                                        }
                                    }
                                })
                            }} defaultValue={this.state.building.Address.Line2} />
                        </td>
                    </tr>
                    <tr style={{ border: "none" }}>
                        <td>City</td>
                        <td><input type="text"
                            onChange={(e) => {
                                this.setState({
                                    ...this.state, building: {
                                        ...this.state.building,
                                        Address: {
                                            ...this.state.building.Address,
                                            City: e.target.value
                                        }
                                    }
                                })
                            }} defaultValue={this.state.building.Address.City} />
                        </td>
                    </tr>
                    <tr style={{ border: "none" }}>
                        <td>State</td>
                        <td><input type="text"
                            onChange={(e) => {
                                this.setState({
                                    ...this.state, building: {
                                        ...this.state.building,
                                        Address: {
                                            ...this.state.building.Address,
                                            State: e.target.value
                                        }
                                    }
                                })
                            }} defaultValue={this.state.building.Address.State} />
                        </td>
                    </tr>
                    <tr style={{ border: "none" }}>
                        <td>Postal Code</td>
                        <td><input type="text"
                            onChange={(e) => {
                                this.setState({
                                    ...this.state, building: {
                                        ...this.state.building,
                                        Address: {
                                            ...this.state.building.Address,
                                            PostalCode: e.target.value
                                        }
                                    }
                                })
                            }} defaultValue={this.state.building.Address.PostalCode} />
                        </td>
                    </tr>
                    <tr style={{ border: "none" }}>
                        <td></td>
                        <td>
                            <button onClick={this.addBuilding}>{this.buildingId ? "Update" : "Add"} Building</button>
                            {this.buildingId && <button onClick={this.deleteBuilding}>Delete Building</button>}
                        </td>
                    </tr>
                </tbody>
            </table>
        </>);
    }

    render() {
        const contents = this.state.loading
            ? <p><em>Loading...</em></p>
            : this.renderForm();

        return contents;
    }

    async populateBuildingData() {
        let data = {};
        if (this.buildingId) {
            const token = await authService.getAccessToken();
            var response = await fetch('api/building/' + this.buildingId, {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
            });
            data = await response.json();
        }

        let address = {
            Id: data.address ? data.address.id : 0,
            Line1: data.address ? data.address.line1 : "",
            Line2: data.address ? data.address.line2 : "",
            City: data.address ? data.address.city : "",
            State: data.address ? data.address.state : "",
            PostalCode: data.address ? data.address.postalCode : ""
        }

        let marketValue = {
            Id: data.marketValue ? data.marketValue.id : 0,
            Studio: data.marketValue ? data.marketValue.Studio : 0,
            OneBed: data.marketValue ? data.marketValue.OneBed : 0,
            TwoBedOneBath: data.marketValue ? data.marketValue.TwoBedOneBath : 0
        }

        this.setState({
            ...this.state,
            building: {
                Id: data.id,
                Name: data.name ? data.name : "",
                PhoneNumber: data.phoneNumber ? data.phoneNumber : "",
                AcquiredDate: data.acquiredDate ? data.acquiredDate : new Date(Date.now()).toISOString(),
                Address: address,
                MarketValue: marketValue
            },
            loading: false
        });
    }

    async addBuilding() {
        if (!BuildingEdit.debounce) {
            BuildingEdit.debounce = true;
            let token = await authService.getAccessToken();
            var response = await fetch('api/building/addOrUpdate', {
                method: 'POST',
                body: JSON.stringify(this.state.building),
                headers: !token ? {} : { 'Authorization': `Bearer ${token}`, 'Content-type': 'application/json' }
            });

            if (response.ok) {
                if (this.state.building.Id) {
                    alert("Building updated")
                } else {
                    alert("Building added")
                }
                window.location.href = '/overview';
            } else {
                alert(await response.text());
                BuildingEdit.debounce = false;
            }
        }
    }

    async deleteBuilding() {
        let token = await authService.getAccessToken();
        if (window.confirm("Are you sure you want to delete Property " + this.state.building.Name + "?")) {
            if (window.confirm("Are you really sure you want to delete Property " + this.state.building.Name + "?")) {
                var response = await fetch('api/building/delete/' + Number(this.state.building.Id), {
                    method: 'DELETE',
                    headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
                });

                if (response.ok) {
                    window.alert("The Property " + this.state.building.Name + " was deleted.");
                    this.props.history.push('/');
                } else {
                    window.confirm("The Property " + this.state.building.Name + " was not deleted.");
                }
            }
        }
    }
}
