import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import { Layout } from './components/Layout';
import { Overview } from './components/Overview';
import { UnitEdit } from './components/UnitEdit';
import { BuildingEdit } from './components/BuildingEdit';
import AuthorizeRoute from './components/api-authorization/AuthorizeRoute';
import ApiAuthorizationRoutes from './components/api-authorization/ApiAuthorizationRoutes';
import { ApplicationPaths } from './components/api-authorization/ApiAuthorizationConstants';

import './css/custom.css'
import './css/site.css'
import './lib/bootstrap/dist/css/bootstrap.css'

export default class App extends Component {
    static displayName = App.name;

    render() {
        return (
            <Layout>
                <AuthorizeRoute exact path='/' component={Overview} />
                <AuthorizeRoute exact path='/overview' component={Overview} />
                <AuthorizeRoute exact path='/unit/:id?' component={UnitEdit} />
                <AuthorizeRoute exact path='/building/:id?' component={BuildingEdit} />
                <Route path={ApplicationPaths.ApiAuthorizationPrefix} component={ApiAuthorizationRoutes} />
            </Layout>
        );
    }
}
